

function Home(){
    
    return <div className="main">
        <h1>Welcome to Benezuela.net</h1>
        <h2>I am in no way associated with Venezuela but I am certainly Ben</h2>
    </div>
}

export default Home;